const PATH = 'fe.pages.learning_resources.educator_home_page';

const messages = {
  searchPlaceholder: {
    key: `${PATH}.search_placeholder`,
    defaultValue: 'Search All Resources'
  }
};

export default messages;
