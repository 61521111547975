import moment from 'moment';
import i18n from 'helpers/i18n';
import messages from './messages';
import { hasCEUs } from './utils';
import { TrainingHistory } from 'types/api/TrainingHistory';

interface TrainingCardProps {
  /** Training History record. */
  training: TrainingHistory;
}

function TrainingCard({ training }: TrainingCardProps) {
  const startDate = moment(training.start_date).format('MM/DD/YY');
  const endDate = moment(training.end_date).format('MM/DD/YY');
  const isLearningPathway = ['LearningPathwayEnrollment'].includes(
    training.source_type || ''
  );
  const isCourse = [
    'SisEnrollment',
    'SisCourse',
    'LegacyTotaraCourse'
  ].includes(training.source_type || '');

  const getLearningTypeLabel = () => {
    if (isCourse) return i18n.ft(messages.onlineCourse);
    if (isLearningPathway) return i18n.ft(messages.learningPathway);
    return i18n.ft(messages.facilitatedTraining);
  };

  return (
    <article className="mb-6 px-6 py-4 bg-white rounded-xl shadow-sm w-full">
      <header className="font-sans font-bold text-lg text-[#1C778C] underline">
        {training.name}
      </header>
      <section>
        <div className="font-extrabold text-sm uppercase mt-2">
          {getLearningTypeLabel()}
        </div>
        <div className="mt-3.5 py-0.5 px-2 inline-flex items-center gap-1.5 bg-lime-200 rounded-full">
          <i className="fa-solid fa-check" />
          <span className="font-semibold text-sm">
            {i18n.ft(messages.complete)}
          </span>
        </div>
        <div className="mt-5 flex flex-col gap-2">
          <div>
            <span className="font-semibold">{i18n.ft(messages.started)}:</span>{' '}
            {startDate}
          </div>
          <div>
            <span className="font-semibold">{i18n.ft(messages.ended)}:</span>{' '}
            {endDate}
          </div>
          <div>
            <span className="font-semibold">{i18n.ft(messages.hours)}:</span>{' '}
            {training.certificate?.hours}
          </div>
          {hasCEUs(training.certificate?.ceus_earned) && (
            <div>
              <span className="font-semibold">{i18n.ft(messages.ceus)}:</span>{' '}
              {training.certificate?.ceus_earned}
            </div>
          )}
        </div>
      </section>
      {Boolean(training.certificate) && (
        <footer className="mt-5">
          <a
            className="font-semibold text-[#1C778C] hover:text-[#1C778C] hover:underline flex items-center gap-2"
            href={`/api/v2/training_certificates/${training.certificate?.id}.pdf`}
          >
            <i className="fa-solid fa-download" />
            <span>{i18n.ft(messages.certificate)}</span>
          </a>
        </footer>
      )}
    </article>
  );
}
export default TrainingCard;
