import Title from 'design-system/Title';
import i18n from 'helpers/i18n';
import messages from './messages';
import { useState } from 'react';
import PageLoader from 'components/PageLoader';
import useCurrentUser from 'hooks/useCurrentUser';
import TrainingCard from 'components/TrainingHistory/TrainingCard';
import Masonry from 'react-masonry-css';
import { Pagination } from 'components/Organization/Pagination';

import {
  TrainingHistory,
  TrainingHistorySummary
} from 'types/api/TrainingHistory';

import {
  useGetTrainingHistory,
  useGetTrainingHistorySummary
} from 'actions/trainingHistory';

const breakpointColumnsObj = {
  default: 3,
  768: 2,
  360: 1
};

type TrainingHistoryData = {
  training_histories: TrainingHistory[];
  pagination: {
    total_pages: number;
    current_page: number;
    per_page: number;
    total_count: number;
  };
};

interface TrainingHistoryPageProps {
  trainings: TrainingHistoryData;
  summary: TrainingHistorySummary;
}

function TrainingHistoryPage({ trainings, summary }: TrainingHistoryPageProps) {
  //TODO: Implement sorting
  // const [sortDir, setSortDir] = useState('desc');
  const [page, setPage] = useState(1);

  const trainingData = trainings.training_histories;

  return (
    <main className="max-w-7xl mx-auto font-sans text-black">
      <Title>{i18n.ft(messages.title)}</Title>

      <section>
        <h2 className="font-sans font-bold text-white text-2xl bg-blue-500 px-6 py-3 rounded-t-xl">
          {i18n.ft(messages.pdAccomplishments)}
        </h2>

        <div className="mt-6 flex gap-6">
          <div className="px-6 py-4 bg-white shadow-sm rounded-lg flex-1 flex items-center justify-between">
            <span className="font-sans text-xl font-bold">
              {i18n.ft(messages.ceus)}
            </span>
            <span className="font-sans text-xl font-bold">
              {summary.total_ceus}
            </span>
          </div>
          <div className="px-6 py-4 bg-white shadow-sm rounded-lg flex-1 flex items-center justify-between">
            <span className="font-sans text-xl font-bold">
              {i18n.ft(messages.hours)}
            </span>
            <span className="font-sans text-xl font-bold">
              {summary.total_hours}
            </span>
          </div>
        </div>
      </section>

      <section className="mt-16">
        <h2 className="font-sans font-bold text-white text-2xl bg-orange-500 px-6 py-3 rounded-t-xl">
          {i18n.ft(messages.trainingCourses)}
        </h2>

        <div className="mt-6">
          <Masonry
            breakpointCols={breakpointColumnsObj}
            className="flex -ml-[15px] w-auto"
            columnClassName="pl-[15px] bg-clip-padding"
          >
            {trainingData.map(training => (
              <TrainingCard key={training.id} training={training} />
            ))}
          </Masonry>
        </div>
        {trainingData.length > 0 && (
          <div className="mt-6 flex justify-center">
            <Pagination
              page={page}
              onPageChange={setPage}
              total={trainings?.pagination.total_pages ?? 0}
              goTo={false}
            />
          </div>
        )}
      </section>
    </main>
  );
}

function TrainingHistoryController() {
  const user = useCurrentUser();

  const { data: summary, isPending: isSummaryPending } =
    useGetTrainingHistorySummary(user.id);

  const params = {
    sort_by: 'desc',
    per_page: 9
  };
  const { data: trainings, isPending: isHistoryPending } =
    useGetTrainingHistory(params);

  if (isSummaryPending || isHistoryPending) {
    return <PageLoader />;
  }

  return <TrainingHistoryPage trainings={trainings!} summary={summary!} />;
}

export default TrainingHistoryController;
