export const DEFAULT_PER_PAGE = 20;
export const DEFAULT_CURRENT_PAGE = 1;
export function getHoursMinutes(seconds: number) {
  let hours = Math.floor((seconds % (3600 * 24)) / 3600);
  let minutes = Math.ceil((seconds % 3600) / 60);

  let totalHours = hours > 0 ? hours + (hours === 1 ? ' hr ' : ' hrs ') : '';

  return totalHours + minutes + ' min';
}

function padTimeUnit(time: number) {
  return String(time).padStart(2, '0');
}

export function getHoursMinutesSeconds(time: number) {
  let hours = Math.floor(time / 3600);
  let minutes = Math.ceil((time % 3600) / 60);

  let totalTime = `${padTimeUnit(minutes)}:00`;

  if (hours > 0) {
    totalTime = `${padTimeUnit(hours)}:` + totalTime;
  }
  return totalTime;
}
