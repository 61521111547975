import i18n from 'helpers/i18n';
import messages from './messages';
import { Input } from 'semantic-ui-react';
import { getMyEducators } from 'actions/coachings';
import { toastError } from 'components/Toast';
import { useCallback, useEffect, useMemo, useState } from 'react';
import FormDropdown from 'components/FormDropdown';
import { Loader } from 'semantic-ui-react';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchResults from 'components/LearningResources/SearchResults';
import ResourcesSection from 'components/LearningResources/ResourcesSection';
import { isEmpty } from 'ramda';

type EducatorProps = {
  id: number;
  guid: string;
  name: string;
};

function Recommend() {
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [educators, setEducators] = useState<any>(null);
  const [selectedAgeLevel, setSelectedAgeLevel] = useState<string>('Pre-K');
  const [selectedEducator, setSelectedEducator] = useState<EducatorProps>({
    id: 0,
    guid: '',
    name: ''
  });
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const guid = searchParams.get('educator_guid');

  const ageLevels = [
    { key: 1, text: 'Infant', value: 'Infant' },
    { key: 2, text: 'Toddler', value: 'Toddler' },
    { key: 3, text: 'PreK', value: 'Pre-K' },
    { key: 4, text: 'K-3', value: 'K-3' }
  ];

  const getEducator = useCallback(
    (guid?: string): EducatorProps => {
      const educator = educators?.find((item: any) => item.value === guid);
      return {
        id: educator?.key,
        guid: educator?.value,
        name: educator?.text
      };
    },
    [educators]
  );

  const handleEducatorChange = useCallback(
    (guid: any) => {
      searchParams.set('educator_guid', guid);
      navigate({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    },
    [navigate, searchParams, location.pathname]
  );

  useEffect(() => {
    getMyEducators()
      .then(({ data }) => {
        const educatorOptions = data.coachings.map((educator: any) => {
          return {
            key: educator.id,
            text: educator.full_name,
            value: educator.guid
          };
        });
        setEducators(educatorOptions);
      })
      .catch(error => {
        toastError(error);
      });
  }, []);

  useEffect(() => {
    if (!educators) {
      return;
    }

    if (guid) {
      setSelectedEducator(getEducator(guid));
    } else {
      setSelectedEducator({
        id: educators[0]?.key,
        guid: educators[0]?.value,
        name: educators[0]?.text
      });
      handleEducatorChange(educators[0]?.value);
    }
  }, [guid, getEducator, educators, handleEducatorChange]);

  if (!educators) {
    return <Loader active />;
  }

  return (
    <div className="mt-8">
      <div className="grid grid-cols-1 lg:grid-cols-7 gap-2 mb-8">
        <div className="col-span-1 lg:col-span-2">
          <div className="font-semibold text-black mb-1">
            {i18n.ft(messages.select)}
          </div>
          <FormDropdown
            onChange={(_, data) => {
              handleEducatorChange(data.value);
            }}
            value={selectedEducator.guid}
            loading={false}
            options={educators}
          />
        </div>
        <div className="col-span-1 lg:col-span-2">
          <div className="font-semibold text-black mb-1">
            {i18n.ft(messages.ageLevel)}
          </div>
          <FormDropdown
            onChange={(_, data) => {
              setSelectedAgeLevel(data.value as string);
            }}
            value={selectedAgeLevel}
            loading={false}
            options={ageLevels}
          />
        </div>
        <div className="col-span-1 lg:col-span-3">
          <div className="font-semibold text-black mb-1"></div>
          <Input
            fluid
            id="search"
            value={query}
            className="mt-6"
            onChange={(e, data) => setQuery(data.value)}
            placeholder={i18n.ft(messages.searchPlaceholder)}
            icon={
              isEmpty(query)
                ? 'search'
                : { link: true, name: 'times', onClick: () => setQuery('') }
            }
          />
        </div>
      </div>
      {query === '' && selectedEducator.id ? (
        <>
          {/* Completed Resources section */}
          <ResourcesSection
            action={'completedRecommended'}
            educatorId={selectedEducator.id}
            educatorName={selectedEducator.name}
          />

          {/* Previously recommended to Educator section */}
          <ResourcesSection
            action={'recommendedResources'}
            educatorGuid={selectedEducator.guid}
            educatorName={selectedEducator.name}
          />

          {/* Classroom Scores for Educator section */}
          <ResourcesSection
            action={'classroomScores'}
            educatorId={selectedEducator.id}
            educatorName={selectedEducator.name}
            ageLevel={selectedAgeLevel}
          />
        </>
      ) : (
        <SearchResults query={query} />
      )}
    </div>
  );
}

export default Recommend;
